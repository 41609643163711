import React from "react";
import "./shop.css";

const Shop = () => {
  return (
    <div className="shop-container">
      <div className="shop-detail">
        <h1>Coming soon</h1>
        <p>
          Thank you for your interest, this website is newly made and the shop
          is coming Soon!
        </p>
        <p>
          In the mean time email or send me a dm. Some prints and acrylic prints
          can be found on{" "}
          <a
            href="https://www.thepublicartgallery.com/vincent"
            target="_blank"
            rel="noopener noreferrer"
          >
            The Public Art
          </a>
        </p>
        <p>
          For booking, please contact me on{" "}
          <a
            href="https://www.instagram.com/inzy.jpg"
            target="_blank"
            rel="noopener noreferrer"
          >
            Instagram{" "}
          </a>
          or send and email to [insert my domain name]@live.se
        </p>{" "}
        <p>Any prints of 50x70 costs 500 per print</p>
        {/* You can add more details or a layout for the shop in the future */}
      </div>
    </div>
  );
};

export default Shop;
