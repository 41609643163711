import React from "react";
import { useParams, useNavigate } from "react-router-dom"; // Import useHistory
import "./photography.css";

const Photography = ({ data }) => {
  const { id } = useParams();
  const photo = data[parseInt(id)];
  const navigate = useNavigate(); // Initialize useHistory

  const handleBack = () => {
    navigate(-1); // Navigate back to the previous page
  };

  return (
    <div className="photography-container">
      <button className="back-button" onClick={handleBack}>
        <span className="arrow">◄</span> {/* ← */}
      </button>
      <div className="photography-image">
        <img src={photo.bg} alt={photo.title} />
      </div>
      <div className="photography-details">
        <h1>{photo.title}</h1>
        <p>{photo.description}</p>
        {/* Add more details as needed */}
      </div>
    </div>
  );
};

export default Photography;
