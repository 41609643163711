import React from "react";
import { Link } from "react-router-dom";
import "./navbar.css"; // Create a CSS file for styling

const NavBar = () => {
  return (
    <nav className="navbar">
      <div className="logo">
        <Link to="/">
          <img
            src="/vw192.png"
            alt="VW Logo"
            style={{ width: "50px", height: "auto" }}
          />
        </Link>
      </div>
      <div className="nav-links">
        <Link to="/about">About</Link>
        <Link to="/shop">Shop</Link>
      </div>
    </nav>
  );
};

export default NavBar;
