import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import NavBar from "./component/navbar/navbar";
import About from "./component/pages/about/about";
import Shop from "./component/pages/shop/shop";

import PhotographyPortfolio from "./component/photographyPortfolio";
import Photography from "./component/photography/photography";
import { DataProvider } from "./dataContext";

import "./App.css";
import Footer from "./component/footer/footer";

function App() {
  const data = [
    {
      id: 0,
      title: "Veiled Reflection",
      description:
        '"Veiled Reflection" presents an enigmatic and abstract art piece shrouded in darkness, its reflective surface catching subtle hints of light. The contours and shapes of the sculpture are obscure, leaving its true form and nature concealed from the viewer. This contemporary artwork, bathed in shadow, exudes an aura of mystery and intrigue, challenging viewers to interpret its essence. The indistinct, polished surface creates a play of reflections and light, adding depth and complexity to the scene. The photograph captures the ambiguity and allure of modern art, inviting contemplation and curiosity about the unseen and unknown.',
      bg: "https://images.squarespace-cdn.com/content/v1/65437a459461904461025f60/45581aaf-15f4-4907-af94-ae1f9817cce9/VWP_2441_lowres.jpg?format=1000w",
    },
    {
      id: 1,
      title: "Enclipsed Figurines",
      description:
        '"Enclipsed Figurines" captures the shadowy silhouettes of KAWS and Bearbrick figures in a monochrome dance. This black-and-white film photograph, shrouded in mystery, emphasizes the sculptural forms of the iconic characters. The absence of color draws focus to the interplay of light and darkness, inviting viewers to explore the enigmatic space where art and shadow converge.',
      bg: "https://images.squarespace-cdn.com/content/v1/65437a459461904461025f60/7e8fb8b4-25d0-42ef-a4e5-93035fb76882/bearbrick_kaws_50x70_highres.jpg?format=1000w",
    },
    {
      id: 2,
      title: "Dissonant Elegance",
      description:
        '"Dissonant Elegance" captures a young Korean man exuding retro sophistication, dressed in a patterned suit that reflects a refined vintage style. Standing in a rundown neighborhood adorned with graffiti, the contrast between his fashionable appearance and the gritty surroundings creates a striking visual dissonance. The cigarette in his hand adds to the nostalgic aura, highlighting the unexpected clash between elegance and the raw urban environment. This photograph explores the juxtaposition of polished style against a backdrop of urban decay, inviting contemplation on the intersections of fashion and context.',
      bg: "https://images.squarespace-cdn.com/content/v1/65437a459461904461025f60/f5abd6dc-a7b7-4471-b244-aea1ae62fb0f/random+guy+lowres.jpg?format=1000w",
    },
    {
      id: 3,
      title: "Stockholm Aurora",
      description: `"Stockholm Aurora" presents a breathtaking scene where the historic skyline of Stockholm city is illuminated by the ethereal glow of the Northern Lights. The vibrant hues of green and purple dance across the night sky, casting a surreal light over the city's iconic architecture and waterways. This rare and captivating juxtaposition of urban beauty and natural wonder captures the magical interplay between the celestial phenomenon and the serene urban landscape, offering a moment of awe and serenity amidst the bustling capital.`,
      bg: "https://images.squarespace-cdn.com/content/v1/65437a459461904461025f60/d0c0903f-2be2-421b-941b-168c4c7f0ce4/stadshuset-norrsken_lowres.jpg?format=1000w",
    },
    {
      id: 4,
      title: "Timeless Infinity",
      description:
        '"Timeless Infinity" captures two traditionally dressed Korean ladies standing elegantly in front of the majestic Gyeongbokgung Palace in Seoul. The intricate patterns and vibrant colors of their hanbok attire reflect the rich cultural heritage of Korea. Behind them stretches an infinity hall, extending into the horizon, symbolizing an endless journey through history. The harmonious blend of traditional architecture and serene landscape creates a scene of tranquil beauty, inviting viewers to appreciate the enduring legacy and elegance of Korean culture.',
      bg: "https://images.squarespace-cdn.com/content/v1/65437a459461904461025f60/00a2eec0-ed61-4386-b71f-efea6547aa00/prodigi-global-cfp-28x40-black-frame+%2829%29.png?format=1000w",
    },
    {
      id: 5,
      title: "Zen in the Streets",
      description:
        '"Zen in the Streets" portrays a Chinese monk juxtaposing traditional and contemporary styles. Dressed in classic monk attire, he contrasts this spiritual garb with street fashion elements: Nike Cortez sneakers and a Nike beanie. The combination of these disparate elements creates a unique visual narrative, blending the serene simplicity of monastic life with the modern, urban energy of streetwear. This photograph captures a thought-provoking intersection of tradition and modernity, spirituality and contemporary culture, highlighting the fluidity of identity in a globalized world.',
      bg: "https://lh3.googleusercontent.com/pw/AP1GczMBUXcEs7TIDAJeA5Z6ueMI4X3nFjqOPPQnSQ4hINQHPu49vXSs02UbMKHaqhzR3otywhlSMC1USyqF5VH_OAszGM1PZofJkqT-YmZXDVu2ywBsM8fkVIs3dPRuWM3dWKw2nWKz8-MpnD-9ABeto8rh=w1206-h861-s-no-gm?authuser=3",
    },
    {
      id: 6,
      title: "Moonlit Serenity",
      description: `"Moonlit Serenity" captures the tranquil beauty of a pink lake under the night sky, where the gentle moonlight transforms the water into a soothing blue. The reflection of the stars and the subtle glow from the night sky create a serene and calming atmosphere. The peacefulness of the scene is palpable, as the soft hues blend seamlessly, evoking a sense of quiet reflection and stillness. This photograph offers a moment of calm, inviting viewers to immerse themselves in the serene harmony of nature's nocturnal palette.`,
      bg: "https://images.squarespace-cdn.com/content/v1/65437a459461904461025f60/d9878000-b053-42dc-87a6-a0ce17edf24c/prodigi-global-cfp-40x28-black-frame+%285%29.png?format=1000w",
    },
    {
      id: 7,
      title: "Harmony in Hues",
      description: `"Harmony in Hues" captures a serene scene of carefully stacked rocks emerging from the tranquil waters of a pinkish-purple lake. The soft, pastel tones of the water create a calming backdrop, contrasting with the rugged texture and natural shapes of the rocks. The delicate balance of the stacked stones symbolizes tranquility and mindfulness, while the unique coloration of the lake adds a dreamlike quality to the composition. This photograph invites contemplation on the beauty and harmony found in nature's subtle contrasts and delicate arrangements.`,
      bg: "https://lh3.googleusercontent.com/pw/AP1GczM_uiU5NJGjy3g55V9T0sqSWWJUcHq-7Njf-R9AT9F0AEQkK91UIgzAHCv6epkYBguSdWf6NG48AgVhKX8IqEg_LTFGAamazu4ouxS8Nfnny1_5ath-_VdQuz5PPDyD1KxGyGuNMvHT0N1gndUqh_f4=w615-h861-s-no-gm?authuser=3",
    },

    {
      id: 8,
      title: "Melanocratic Polylith",
      description:
        '"Melanocratic Polylith" fångar naturens gåtfulla skönhet, med grunt mörkgrönt vatten vid basen av en klippa med kubformade stenar, vilket skapar en naturlig geometrisk mystik.',
      bg: "https://images.squarespace-cdn.com/content/v1/65437a459461904461025f60/1716149290926-F6XTW88F174IC8B6T6IL/20_Vincent_50x70_abisko_jpg_lowres_ver1.jpg?format=1000w",
    },
    {
      id: 9,
      title: "Fractured mistake: Middle finger",
      description:
        "Mittfingret symboliserar konstnärens trotsiga styrka och rebelliska energi, ett bevis på en okuvlig anda som inte låter sig besegras av smärta eller utmaningar.",
      bg: "https://images.squarespace-cdn.com/content/v1/65437a459461904461025f60/1716149862135-KAR29T4SEERJLWO7WX3W/21_Vincent_fuckedhand2020_jpg_lowres_ver1.jpg?format=1000w",
    },
    {
      id: 10,
      title: "Rock’n’roll",
      description:
        "Rock’n’roll-gesten visar konstnärens brinnande passion och rebelliska själ, ett bevis på en orubblig anda som står fast trots skada och svårigheter.",
      bg: "https://images.squarespace-cdn.com/content/v1/65437a459461904461025f60/1716151514504-S7A4MVJPY9OQRJ0LGEW2/22_Vincent_xray_hand_2016_rock_n_roll_jpg_lowres_ver1.jpg?format=1000w",
    },
    {
      id: 11,
      title: "Fractured mistake: Västra Sidan",
      description:
        '"Westside"-gesten fångar konstnärens djupa passion och starka koppling till West Coast hip-hop, ett bevis på en oböjlig anda som trotsar både skada och motgång',
      bg: "https://images.squarespace-cdn.com/content/v1/65437a459461904461025f60/1716151695016-9SHMJCW9XA9TYPS48S2M/23_Vincent_hand_2016_westside_jpg_lowres_ver1.jpg?format=1000w",
    },
    {
      id: 12,
      title: "Bee The Sunflower",
      description: "Bee The Sunflower",
      bg: "https://lh3.googleusercontent.com/pw/AP1GczP31SBiNZGpMaS7snt77vi-R55DJjXdYd0KfmdK8PD5Un5qJp_RmGN-3NLu-NT02lV7Q8loD9VMqCBff9UARnNBtNDIX6e2bUwiQheOrSO1DST0cGRzfYJrGpnm1RIZHIiWdN1QI0zQgHdtw1GK0ayg=w1294-h861-s-no-gm?authuser=3",
    },
    {
      id: 13,
      title: "The Red Kite",
      description:
        "A beautiful woman stands elegantly before an old windmill as she holds a colorful kite. The vibrant kite dances in the wind, contrasting with the peaceful, green landscape and the clear blue sky. The scene captures both grace and serenity, blending the timeless charm of nature with a playful moment.",
      bg: "https://lh3.googleusercontent.com/pw/AP1GczPTBaLzc66NYJg2Qe3FquI2T8uz3yZo76InhcLFhRYP75Kkaqk-UhUTpb113LKFvYRS6go4W6nTTsu_haGlGtea8reHsYvjoqggLer6N7MbEHJljcI3eS0O52dfvT8DJRdFzb4qt1WCIbIufKm7nKkJ=w861-h861-s-no-gm?authuser=3",
    },
    {
      id: 14,
      title: "The Majesty of Skógafoss",
      description:
        "In this captivating scene, the mighty Skógafoss waterfall cascades down with a thunderous roar, its powerful streams splashing into the dark black rocks that scatter the foreground. The contrast between the deep, rugged stones and the smooth, ethereal mist rising from the falls evokes a sense of nature's raw beauty. Above, a white sky, softened by the thick fog, merges with the mist, giving the image a mystical atmosphere where earth and sky seem to blur into one. The image captures a moment where serenity and power coexist in perfect harmony.",
      bg: "https://lh3.googleusercontent.com/pw/AP1GczOSchs4vJcNEVu7hPUYLCV4U3uPBHQBf5o4f0ubx-VlaqJb_piSVkFdUzlOoxPKxXNJmbY5lvz3WB9-gqQkk7WzdsS2E8s938nOyjqSq3npBKyyNIvpfRjjzufpZ5wlHlxYPTQAW2u6Q1Gi1PzGtkje=w615-h861-s-no-gm?authuser=3",
    },
    {
      id: 15,
      title: "Lost in Time and Space",
      description:
        "A young woman, dressed in a sleek green puffer jacket, stands boldly in front of the abandoned plane wreck on Iceland’s desolate landscape. The modern fashion contrasts sharply with the worn, skeletal remains of the aircraft, adding a contemporary edge to the scene. Surrounding them is a thick mist, reminiscent of a distant, alien world where the fog stretches infinitely across the barren terrain. The otherworldly atmosphere, enhanced by the space-like fog and mist, evokes a sense of isolation, blending futuristic elements with the haunting remnants of the past. It's a moment caught between worlds, where time and space blur.",
      bg: "https://lh3.googleusercontent.com/pw/AP1GczPd0wFo-WWavZmJAvip1Dcm55stArx4FJ1Z29QESefVIV40oLNaA2PPakksM-kFrivYVIv2APOhBE6QVe1lRPkCRgPEiKu7GzdrzB_kRWD3xFnO_ZEwA9OWn0ZFZKpK_XvQxtO06lskr1pRkP6i6G2R=w1294-h861-s-no-gm?authuser=3",
    },
    {
      id: 16,
      title: "Stuðlagil’s Timeless Canyon",
      description:
        "Stuðlagil Canyon, a hidden gem of Iceland, reveals its stunning basalt columns, towering in geometric perfection along the river’s edge. The dark, hexagonal pillars stand like ancient guardians, etched by time, creating a surreal, almost alien landscape. The river flows serenely through the canyon, its calm waters in contrast with the rugged stone formations. Above, a soft mist clings to the scene, shrouding the cliffs in mystery. The sheer scale and symmetry of the basalt formations give the canyon an otherworldly beauty, a place where nature's artistry is on full display.",
      bg: "https://lh3.googleusercontent.com/pw/AP1GczPIF-Ii7zsRaxZQ2q1p5-LTXJYJh2eDAjZAhtrmXwAlDhDThz2Vsgy1GGr8ck2mskuam8jMryT75i_v_7Ac92kYfZSwWNpjZhQYCXUjc5bU30wexSq0Eau3SIhMqq0dLgfDr5JY773ARd_2lkmIr1QV=w573-h861-s-no-gm?authuser=3",
    },
    {
      id: 17,
      title: "Stuðlagil other pov",
      description:
        "Stuðlagil Canyon, a hidden gem of Iceland, reveals its stunning basalt columns, towering in geometric perfection along the river’s edge. The dark, hexagonal pillars stand like ancient guardians, etched by time, creating a surreal, almost alien landscape. The river flows serenely through the canyon, its calm waters in contrast with the rugged stone formations. Above, a soft mist clings to the scene, shrouding the cliffs in mystery. The sheer scale and symmetry of the basalt formations give the canyon an otherworldly beauty, a place where nature's artistry is on full display.",
      bg: "https://lh3.googleusercontent.com/pw/AP1GczPl5arusf4zb6TXm5xWj_H9rCLuw887ite_YO10wtuodbUiogNq_XEMJlv8LFlEnPKBEJNAUd9GSxE5zhFiM7_5et8kWWqO4PtnVrmsQ6QjkG2zHjkkShNPTczyWV2inZWpQpPnkMFWk8RgPtp9PRiN=w615-h861-s-no-gm?authuser=3",
    },
    {
      id: 18,
      title: "Everyday Warrior: Wushu in Motion",
      description:
        'Captured in the vibrant yellow-walled tunnel of Stockholm, a Wushu athlete defies the ordinary with an unexpected, airborne display of skill. Mid-jump, he wields his bamboo staff with precision, embodying the grace and discipline of the ancient martial art. The tunnel, with its clean lines and bright yellow walls, frames this fleeting moment of athleticism, transforming an everyday urban setting into a stage for an extraordinary performance. Created for the Nikon competition under the theme "Everyday Athlete, Unexpected Performances," the image juxtaposes modern city life with the timeless elegance of Wushu, celebrating the fusion of tradition and spontaneous action in the most unexpected places.',
      bg: "https://lh3.googleusercontent.com/pw/AP1GczO8USi44A4WwpwjoMBC2cyZf23Look5TUJHEfd3vV2ygYhXdYq7fo7bxF6r_gxoN8nKzBe7t6sh9oLVjjp7us3kumgv05i_mB71z9KIV3yGU5GL8vDAx2W-ZbwjR32nZs-ovg5oPVh22NBIT8LgFM6_=w1294-h861-s-no-gm?authuser=3",
    },
    {
      id: 19,
      title: "The Cleaning Kick",
      description:
        'In front of a set of sleek elevators, a cleaner transforms his everyday task into an unexpected display of athletic prowess. Caught mid-air, mop in hand, he delivers a powerful jump kick, blurring the line between chore and combat. His pose, more reminiscent of a martial artist than a cleaner, exudes energy and confidence, as if he’s fighting grime with every move. The contrast between the routine setting of elevators and the dynamic action makes this image a celebration of hidden talent and unassuming strength. Captured for the Nikon competition with the theme "Everyday Athlete, Unexpected Performances," this photo redefines what it means to be an athlete in the most surprising moments.',
      bg: "https://lh3.googleusercontent.com/pw/AP1GczPsCPy7gb2e70RoV79fG958ihMQMTYoTZA5T6IUKCqkWA-PPFu96FfxlJ-gVMG1rMQfbu3iKnQMKEs8ml7YkVZ33izpSzOEU6RrDjjiDVLzrgsBHn25TkPSu7qTEZH-ZS4KtfzC1cRnEVXBOoWBo-Tv=w1294-h861-s-no-gm?authuser=3",
    },
    {
      id: 20,
      title: "Balancing Act",
      description:
        'In a surreal twist, a cleaner hovers mid-air, perfectly balanced atop his mop in front of the elevators. The mundane scene transforms into something extraordinary as the man floats effortlessly, defying gravity and expectation. His posture is calm and poised, turning a simple cleaning tool into a symbol of balance and mastery. The elevators, with their sleek, modern design, provide a neutral backdrop, letting the unexpected nature of his performance shine through. Taken for the Nikon competition under the theme "Everyday Athlete, Unexpected Performances," this image celebrates the magic hidden in routine tasks, where even cleaning becomes an act of wonder and skill.',
      bg: "https://lh3.googleusercontent.com/pw/AP1GczMIgEEMz9x7HY5FDI7_VeImXJhJ-vcbtv-eSM4Pai7kn7pHaO7BmLGiDoGg7K-CNl8mFOrZZc9lrS5udpZC4ZICFFwxQyyfePQJaL8ws11I50vD6Wbr2G4GsBVU8RkRkpYQHay8P8o73y07iLJ_0Jq_=w1294-h861-s-no-gm?authuser=3",
    },
    {
      id: 21,
      title: "Kyle Dion in New York Groove",
      description:
        "In the heart of New York, Kyle Dion is captured in his element, radiating charisma and cool against the bustling backdrop of the city that never sleeps. The urban landscape complements his bold, unapologetic style, creating a synergy between the artist and the iconic energy of NYC. With towering skyscrapers and vibrant city streets as his stage, this shot captures both the essence of the metropolis and the soulful vibe that Kyle brings, making New York feel like his playground.",
      bg: "https://lh3.googleusercontent.com/pw/AP1GczON_ri26mRZONr_30RL0iZVMKv6PITFcVekTmKINXNOz5voXClC0EIvxEtaqFNDA0itkEzsseRjkovy2gksICN5SEOeEww_d7e80o50j1gtW3oPJkylNzbuqs1OEIIevcDoggJ6KGi7eqDENbQIAbHP=w615-h861-s-no-gm?authuser=3",
    },
    {
      id: 22,
      title: "Burna Boy at Way Out West",
      description:
        "At Gothenburg's Way Out West festival, Burna Boy commands the stage, delivering an electrifying performance that pulses through the crowd. The Swedish summer sky contrasts with the festival's vibrant energy as Burna Boy’s Afrobeat rhythms infuse the air, turning the moment into an unforgettable musical experience. With the city of Gothenburg as a backdrop, this photo captures Burna Boy's larger-than-life presence and the cross-cultural magic of his sound, bringing together fans from all walks of life in a celebration of music and movement.",
      bg: "https://lh3.googleusercontent.com/pw/AP1GczNih9VUwy5fsk9Fhd56FKXqLbKp0J3ZM4OEFBRTTpGl22fc3RO4wwDNpdK_4aFYaHnBKwseJ879sc5cGIk_IdElJzTbl8WI45au9L3GCZLuFX3Qq-eg6YCkcVXaF9ov70BOb6fWQ7HSCGumo7N2EJck=w615-h861-s-no-gm?authuser=3",
    },
    {
      id: 23,
      title: "Leap of Love",
      description:
        "In a playful and joyful moment, a groom is captured mid-air, arms stretched wide with exuberance as he leaps into the air. Below him, his bride stands grounded, arms poised and ready to catch him, sharing in the laughter and spontaneity of the moment. The contrast between the groom's carefree jump and the bride’s steady, supportive stance creates a beautiful depiction of their partnership. This lighthearted scene captures the essence of love—fun, trust, and the joy of shared adventures—embodied in this leap toward their new life together.",
      bg: "https://lh3.googleusercontent.com/pw/AP1GczPX9IhjzPEXO064NGtfemmUU0z5jO1uYmULoL7tMXmGJipMNyw_2PzyypXXe4sMNG00BPK-w6D1-7Ww_SZm0xur67il1jcyLlqQJzziT8PDHXgxt-Ak2KYMLLd4odwiplEs7nS3Gt1M3XhI7j4GIYEo=w916-h1376-s-no-gm?authuser=3",
    },
    {
      id: 24,
      title: "Jorja in Gröna Lund",
      description:
        "Against the nostalgic backdrop of Stockholm’s iconic amusement park, Gröna Lund, Jorja Smith is captured on film, exuding both elegance and authenticity. The grainy texture of the film adds a timeless quality to the image, highlighting her natural beauty and effortless presence amidst the lively, colorful atmosphere of the park. The blend of soft lights and carnival energy contrasts with Jorja’s calm, grounded demeanor, creating a captivating moment that feels both intimate and cinematic. This snapshot of Jorja in Gröna Lund merges the charm of the past with the vibrancy of the present.",
      bg: "https://lh3.googleusercontent.com/pw/AP1GczPK4eEJNyoybGm2M1EIDAMCVYGShiiOtq3nX4LIbNYxoMRG7Qb5JSPjfMcjFAGcLQ9XM6HDrLx_GUsEgplbHxF22UIkj2PsLZ32UT1Zw8i8IFZJZo3aTYLIcqRBnEwnAB3HoNZVX4cmc3d0vPyvbgAM=w982-h1376-s-no-gm?authuser=3",
    },
    {
      id: 25,
      title: "Tradition and Elegance: A Japanese Wedding",
      description:
        "In a moment of timeless beauty, a traditional Japanese wedding unfolds with grace and serenity. The bride, adorned in a stunning white shiromuku kimono, walks beside her groom in formal attire, their steps careful and dignified. Surrounding them are the delicate details of Japanese culture—intricate kanzashi hairpieces, vibrant umbrellas, and the ceremonial atmosphere of a historic shrine. The harmonious blend of color and tradition, paired with the reverence of the occasion, creates an image of deep cultural heritage and the beginning of a lifelong journey together.",
      bg: "https://lh3.googleusercontent.com/pw/AP1GczMIaoh2Z_vu10szPzIZpfOtmmRQByMW4I0oCQc4FzNcKXecCKY1aawhElzHWUIqN_X5O2rgRkeTTgWgE9EkPSlqEJTiRxUt2Vwf_FL6GRJWY4lpLg--Mi4y48q3hOX6Nu_UgMuzJgPc9Pug9-rS0tII=w1926-h1376-s-no-gm?authuser=3",
    },
    {
      id: 26,
      title: "Blood Moon Flight",
      description:
        "A mesmerizing blood moon dominates the night sky, its deep red hue casting an eerie glow across the heavens. In the bottom middle of the moon's surface, the silhouette of a bird soars gracefully, its wings outstretched against the celestial backdrop. The bird’s shadowy figure adds a sense of mystery and quiet power to the scene, emphasizing the scale and beauty of the lunar eclipse. This haunting image captures the rare and awe-inspiring union of nature’s most enchanting elements—darkness, light, and life in motion.",
      bg: "https://lh3.googleusercontent.com/pw/AP1GczPebILi647BH6LTK8XqtOpzL1gt_qt0A7a2OsPpcZlbscdM8ash3-bJCEr2qQy5BYg_f-LY0zbfBjkH77fIKRrQ0M15B3TfPQGOKF3NhtZh76RIouULdZ7qVP0NBCfJE_C2ncGVaHozARBvJLkK42Wr=w1206-h1207-s-no-gm?authuser=3",
    },
    {
      id: 27,
      title: "Molly Sandén’s New Song Unveiled",
      description:
        "Under a moody, overcast sky at Gröna Lund, Molly Sandén took the stage with electric anticipation in the air. Moments before the rain would add its dramatic touch, she debuted a brand new song, captivating the audience with her emotional performance. The darkening sky seemed to echo the depth of her lyrics, building an atmosphere of raw emotion. With the crowd hanging on every note, this moment captured Molly's artistry in its purest form—a blend of vulnerability and strength, as she shared something new with the world amidst the impending rain.",
      bg: "https://lh3.googleusercontent.com/pw/AP1GczMxQxCahJiwKUSA6N-UTNjI65NkO4Y3xKcaTotNF0y-robqrXV2t8sEJgCl8bEdMEYNKYWbMSMiR-JvyIjIZnMo70raQlbJy1Y4LAJp1jmGR7u1lXJdZxpGFxihJrZxJuISmtfzS-5uByNq76t9gUhw=w984-h1376-s-no-gm?authuser=3",
    },
    {
      id: 28,
      title: "Rain Dance with Melinda de Lange",
      description:
        "Amidst the rain-soaked stage of Gröna Lund, Melinda de Lange moves with grace as she performs as a backup dancer for Molly Sandén. The rain, rather than a hindrance, adds an ethereal quality to the scene, with droplets distorting the light and creating a dreamy, cinematic effect. The wet stage reflects the movement, amplifying the energy of the performance as Melinda dances through the downpour. The beauty of this moment lies in the harmony between the dancer, the rain, and the music—captured perfectly in a photograph where nature and art fuse into something magical.",
      bg: "https://lh3.googleusercontent.com/pw/AP1GczO1a3TEU8D_bs7DKu6p25YR0DJUKB8XeEOcM5TBFzOUbM5Ht-3J94Z4K9qtL0EsyJCw0AFRFOP_C5vO5p5LgA9J7ucGq47FZ01_FKVKkHHQBryPXbHJ0nOIG9isxUs1ZmE7IsTMsgC2dXFMXC54B-qf=w982-h1376-s-no-gm?authuser=3",
    },
    {
      id: 29,
      title: "Rambow: The New Norwegian Voice",
      description:
        "In this striking headshot, Rambow, the fresh and exciting Norwegian rapper, stares confidently into the camera, exuding charisma and raw talent. His youthful energy is palpable, with a fierce determination in his eyes that speaks to his rise in the music scene. The close-up highlights his unique style and presence, a blend of modern flair and streetwise attitude, marking him as a standout in Norway’s evolving hip-hop landscape. This portrait captures not just the face of an artist but the intensity and passion of a rising star ready to make his mark.",
      bg: "https://lh3.googleusercontent.com/pw/AP1GczNNNTIvQrkKJ7_kXICd9-KxdEIilQUCcD_-QSrmti6dlOcB-Slm1GbvH1eL8esy1buyV95-_UCE2lIVHdBfyJh7ZpaeMn8DE1NQ6QYgnmXDqLxBAbrZMKX8bN4Jx43ZskhYWkCiHXMXovZFZU4K5kqw=w982-h1376-s-no-gm?authuser=3",
    },
    {
      id: 30,
      title: "August 08: A Moment in Stockholm",
      description:
        "Captured during his first and only visit to Stockholm, August 08 brought soulful energy to the stage as the pre-act for Rich Brian. This poignant image freezes a fleeting moment, where the late artist connected deeply with the crowd, his voice filling the venue with emotion. The atmospheric lighting and intimate setting reflect the essence of his music—melancholic, yet hopeful. This photo serves as a lasting memory of August 08’s brief but impactful presence in Stockholm, commemorating a night where his talent left a lasting imprint on everyone who witnessed it.",
      bg: "https://lh3.googleusercontent.com/pw/AP1GczORJ4L1fRRNauaGX6uvWE5JVt268wCpf8QN4dE7R51HHpWbW1l44nm7OluSBB_x9Pp2q32ESYC3dAvsuGICAq_F035ZpV79uReSU-qm0T4yy_k7zC2p_K1f5DxfkPxJLldreD5bP-ho5YaAygNK6nmf=w982-h1376-s-no-gm?authuser=3",
    },
    {
      id: 31,
      title: "Anji: Joyful Beginnings",
      description:
        "In this radiant portrait, Anji, Sweden's emerging pop princess, beams with joy and youthful energy before her rise to stardom. Her infectious smile lights up the frame, capturing a moment of pure, unfiltered happiness. Even before fame found her, Anji’s charisma shines through, a testament to her boundless spirit and undeniable star quality. This image encapsulates the early days of an artist whose joy and passion continue to define her as she steps into the spotlight.",
      bg: "https://lh3.googleusercontent.com/pw/AP1GczP9ryqrdO_1UIq9E2ET3EaC5ys31tJHWq2UJKikkkgAmqlI9f_6NNLJ1023iySHbyi84_i0c1oBrweBZ7mNdUQK7r4vM9BbAqjazXrpj29v48-E-U4pDyRG7JlVlX926OjTnUB7Sdvoh-jiTnwcJ2VU=w2068-h1376-s-no-gm?authuser=3",
    },
    // {
    //   id: 8,
    //   title: "Crimson Stones",
    //   description: `"Crimson Stones" presents a surreal landscape where rocks are nestled in the tranquil waters of a pink-hued lake. The striking contrast between the solid, earthy texture of the rocks and the soft, ethereal color of the water creates a dreamlike scene. The unique pigmentation of the lake, possibly caused by natural elements like algae or minerals, bathes the rocks in a gentle, rosy glow. This photograph captures the otherworldly beauty and serene atmosphere of a landscape where nature's elements come together in unexpected harmony.`,
    //   bg: "https://png.pngtree.com/thumb_back/fh260/background/20201015/pngtree-white-polygonal-mosaic-triangular-background-abstract-light-gray-background-low-poly-image_418752.jpg",
    // },
    // sword
    //     https://lh3.googleusercontent.com/pw/AP1GczP9kZUIHBIukuYSEQU2G97JfDNBOASTfyKuPXlyyxZlHdd_qLVMukU3_OAjeE1VWNxh_cUHdRO-EQ1STK1oTVP7KkM_laFCZpSnxT5ruUOuW26meKWF1k2ntOpxPuxX6UR040qSKz9n0I29hO5sF_tx=w1294-h861-s-no-gm?authuser=3
    //     leg
    //     https://lh3.googleusercontent.com/pw/AP1GczNCibXupzAfw9thG3uh5RhPHQCwmJA5KhEFchZziVI7RZl3nx-_2o2cHM_Yxii5_P5gO-OPgCXm9SZxO_5dioSCHUmcCjBQP7IBVcMrDo0icKz94HwD4roJ_ZYvQc0O1vEqWis6FHR9u_BmKFZsgDax=w1294-h861-s-no-gm?authuser=3
  ];
  // const shuffledData = data.sort(() => Math.random() - 0.5);

  return (
    <DataProvider data={data}>
      <Router>
        <NavBar />
        <Routes>
          <Route path="/" element={<PhotographyPortfolio data={data} />} />{" "}
          <Route
            path="/photography/:id"
            element={<Photography data={data} />}
          />
          <Route path="/about" element={<About />} />
          <Route path="/shop" element={<Shop />} />
        </Routes>
        <Footer />
      </Router>
    </DataProvider>
  );
}

export default App;
