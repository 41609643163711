import React from "react";
import "./about.css"; // Import the CSS file for styling

const About = () => {
  return (
    <div className="about-container">
      <div className="about-image">
        <img
          src="https://images.squarespace-cdn.com/content/v1/65437a459461904461025f60/869611fb-38f5-4dd9-9048-4ef2bc6c02ae/wangdi-3.JPG?format=2500w"
          alt="Placeholder"
          className="about-img"
        />
      </div>
      <div className="about-details">
        <h1>Vincent Wong</h1>
        <p className="about-text">
          Since childhood, Vincent Wong has had a deep interest in both art and
          science. Despite his dreams of leading a creative life, he graduated
          in a scientific field and humorously refers to himself as an "art
          engineer." His creations blend contemporary art and technology, but
          photography has always been his primary form of expression, sparked by
          an early fascination with the Northern Lights and the night sky.
        </p>
        <p className="about-text">
          Today, his photography spans everything from nature and street life to
          international artists like Dua Lipa and Post Malone, as well as local
          stars like Veronica Maggio. Although he is still in the early stages
          of his photography career, his interactive and technically advanced
          works have already been showcased at prestigious venues like the Dubai
          Expo, where he represented Sweden, and have been flown to Australia
          for exclusive exhibitions.
        </p>
        <p className="about-text">
          Vincent combines contemporary art, photography, and his technical
          expertise to create works that are both interactive and artistic. He
          now hopes to kickstart his professional career as a photographer and
          gain recognition for his artistic vision in the world of photography.
        </p>
      </div>
    </div>
  );
};

export default About;
