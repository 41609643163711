import React from "react";
import "./photographyPortfolio.css";
import { Link } from "react-router-dom"; // {{ edit_1 }}

// List of sample image URLs for portfolio

const PhotographyPortfolio = ({ data }) => {
  return (
    <div className="portfolio-grid">
      {data.map((item) => (
        <Link
          to={`/photography/${item.id}`}
          key={item.id}
          className="portfolio-item"
        >
          <img src={item.bg} alt={`Portfolio ${item.id}`} />
        </Link>
      ))}
    </div>
  );
};

export default PhotographyPortfolio;
